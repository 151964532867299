import { Component, OnInit } from '@angular/core';
import {perferred} from '../../domain/perferred';
import {scheduleevent} from '../../domain/scheduleevent';
import {flexperiods} from '../../domain/flexperiods';
import {schedulepreferredservices} from '../../domain/schedulepreferredservices';
import { User } from '../../domain/user';
import { UserDetailsService } from '../../service/userDetails.service';
import { flexperiodsservice } from '../../service/flexperiodsservice';
import { perferredservice } from '../../service/perferredservice';
import { scheduleeventservice } from '../../service/scheduleeventservice';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Validators,FormBuilder} from '@angular/forms';
import { targettypesservice } from '../../service/targettypesservice';
import { targettypes } from '../../domain/targettypes';
import { hubs } from '../../domain/hubs';
import { hubservice } from '../../service/hubservice';
import { locationservice } from '../../service/locationservice';
import { VisitService } from '../../service/visitservice';
import { Visit } from '../../domain/visit';

@Component({
  selector: 'app-preferredservices',
  templateUrl: './preferredservices.component.html',
  styleUrls: ['./preferredservices.component.scss']
})
export class PreferredservicesComponent implements OnInit {
  options: any;
  overlays: any[];
  marker: any;
  users:any[]=[];
  servicedialog : boolean;
  officerdialog : boolean;
  infoWindow: any;
  title : any;
  selectedPrefService: Visit;
  clickedUser: User;
  selectedUser: User;
  perferred: perferred[];
  visit:Visit[];
  perfer : perferred = {};
  sse :scheduleevent;
  sps :schedulepreferredservices;
  isSubmitted = false;
  isSearchSubmitted = false;
  officers: User[];
  officerstemp: User[];

  targettypes: targettypes[];
  targettypestemp: targettypes[];

  flexperiods : flexperiods[];
  flexperiodstemp : flexperiods [];

  hubs : hubs[];
  hubstemp : hubs [];

  selectedflexperiodname: flexperiods;
  constructor(private flexperiodsservice:flexperiodsservice,private hubservice: hubservice,
    private targettypesservice:targettypesservice,private userdetailsservice :UserDetailsService,
    private scheduleeventservice: scheduleeventservice,private locationservice : locationservice,private visitservice: VisitService,
    private messageService: MessageService,private perferredservice :perferredservice,public fb: FormBuilder) { }
  assignscheduleform = this.fb.group({
    sflexperiod: ['', [Validators.required]]
  });

  searchtargetofficerform= this.fb.group({
    ftargettype: ['', []],
    fservicehub: ['', []],
    fofficerhub: ['', []]
  });

  
  searchtargetservicesform= this.fb.group({
    fSertargettype: ['', []],
    fSerservicehub: ['', []],
    fSerservicenumber: ['', []],
    fSerperiod: ['', []]

  });


  get ftargettype() {
    return this.searchtargetofficerform.get('ftargettype');
  }
  get fservicehub() {
    return this.searchtargetofficerform.get('fservicehub');
  }
  get fofficerhub() {
    return this.searchtargetofficerform.get('fofficerhub');
  }

  get sflexperiod() {
    return this.assignscheduleform.get('sflexperiod');
  }


  get fSertargettype() {
    return this.searchtargetservicesform.get('fSertargettype');
  }
  get fSerservicehub() {
    return this.searchtargetservicesform.get('fSerservicehub');
  }
  get fSerservicenumber() {
    return this.searchtargetservicesform.get('fSerservicenumber');
  }
  get fSerperiod() {
    return this.searchtargetservicesform.get('fSerperiod');
  }


  ngOnInit(): void {
    // get target types for drop down.
    this.gettargettypes();
    // get hubs for officer
    this.gethubs();
    // get hubs for services

    // get user data to plot on maps.
    console.log("-------inside nginit---------");
    this.userdetailsservice.getAllActiveUsers().subscribe(userdata =>{ 
      if(userdata!=null){
        console.log("userdata"+userdata.length)
        this.users = userdata;
        this.overlays = [];
        this.users  =   userdata.filter(e=>e.prefLocationOne!=null && e.userFirstName!='SURRENDERED' && (e.title=='FO' || e.title=='SFO' || e.title=='FORD' || e.title=='SFORD' ) ) 
        console.log("this.users.."+this.users.length)

        this.users.forEach(eledata=>{

          console.log("eledata.userFirstName:  "+eledata.userFirstName)
          this.locationservice.getLocationsforId(eledata.prefLocationOne).subscribe(loc=>{
                let path='';

                if(eledata.title =='SFO' || eledata.title =='SFORD'){
                  path ='../../../assets/layout/images/personpin_red.png'
                } else if(eledata.title =='FO' || eledata.title =='FORD'){
                  path ='../../../assets/layout/images/personpin_purple.png'
                }  
                let randonnumber = Math.floor(Math.random() * 90 + 10)

                  //check if this location is duplicated
                 let checkduplicatelist = this.users.filter(d => d.prefLocationOne==eledata.prefLocationOne);
                let lat =Number(loc.latitude)
                let long = Number(loc.longitude)
                if(checkduplicatelist.length>1)
                {
                  lat = lat - randonnumber*this.COORDINATE_OFFSET;
                  long = long - randonnumber*this.COORDINATE_OFFSET;
                }
                this.overlays.push(new google.maps.Marker({ position: { lat: lat, lng: long }, 
                title: eledata.userFirstName+" "+eledata.userLastName+"|"+eledata.userId + (checkduplicatelist.length>1?'| '+checkduplicatelist.length +' more users at same location ':''), icon: path,draggable:false }
                
                ));
           });
             
        });               
            // service data to plot on maps
     //   this.perferredservice.getAllPerferredService().subscribe(preferreddata =>{       // blocking this code for now
           this.visitservice.getVisitsPreferredServices().subscribe(preferreddata =>{     

          this.visit = preferreddata; 
         
          this.visit.forEach(eledata=>{
                if (eledata.latitude !=null && eledata.longitude !=null) { 
             //     console.log("eledata lat..."+eledata.serviceType)
                  let path='';
                  if(eledata.serviceType =='Centre-Based Care'){
                    path ='../../../assets/layout/images/childcare_multicolor.png'
                  } else if(eledata.serviceType =='Out of Scope')
                  {
                    path ='../../../assets/layout/images/mobile_bus1.png'

                  }
                  else {
                   // path ='../../../assets/layout/images/MobileCare.png'

                    path ='../../../assets/layout/images/fdc_color.png'
                  }              
                  this.overlays.push(new google.maps.Marker({ position: { lat: Number(eledata.latitude), lng: Number(eledata.longitude) }, 
                  title: eledata.serviceName+"|"+eledata.preSerId+"|"+eledata.serviceId, icon: path,draggable:false }
                  
                  ));
                } 
          });       
        });  
      }     
    });

//get all active flex periods
    this.flexperiodsservice.getFlexPeriods().subscribe(flexdata =>{      
      if(flexdata!=null){
        this.flexperiodstemp = flexdata;  
        this.flexperiods = [];
        this.flexperiodstemp.forEach(eledata=>{
          // show only active flex periods            
          if(eledata.status =='Active'){
            this.flexperiods.push(eledata);
          }
        }); 
      }
    });

    this.options = {
      center: {lat: -33.8039091 ,lng: 150.7031746},
      zoom: 10
    };

    this.infoWindow = new google.maps.InfoWindow();
  }


  handleOverlayClick(event) {    
    console.log("hello 2");
    this.title = event.overlay.getTitle();
    var fields = this.title.split('|');
    if(fields[1].startsWith('PSE')){
      let data = this.visit.filter(e=>e.preSerId==fields[1])
      if(data!=null && data!=undefined && data.length>0)
        this.selectedPrefService = data[0];    
            // get active flex periods
            this.flexperiods=[];
      this.flexperiodsservice.getFlexPeriods().subscribe(flexdata =>{      
        if(flexdata!=null){
          this.flexperiodstemp = flexdata;  
          this.flexperiods = [];
          this.flexperiodstemp.forEach(eledata=>{
            // show only active flex periods            
            if(eledata.status =='Active'){
              this.flexperiods.push(eledata);
            }
          }); 
        }
      });
      this.servicedialog = true;
    } else {
      this.selectedUser;
      this.officerdialog = true;
      this.userdetailsservice.getUserforId(fields[1]).subscribe(userdata =>{      
        this.clickedUser = userdata;   
      }); 
    }
  }

  saveofficer(){
    this.selectedUser = this.clickedUser;
    console.log(this.selectedUser.userFirstName);
    this.officerdialog = false;
  }

  gettargettypes(){
    this.targettypesservice.getTargetTypes().subscribe(targetData=>{
      if(targetData!=null){
        this.targettypestemp = targetData;  
        this.targettypes = [];
        this.targettypestemp.forEach(eledata=>{
          // show only Status=='Active' events 
          if(eledata.status =='Active'){
            this.targettypes.push(eledata);
          }
        }); 
      }
    });
  }

  gethubs(){
    this.hubservice.getHubs().subscribe(hubData=>{
      if(hubData!=null){
        this.hubstemp = hubData;  
        this.hubs = [];
        this.hubstemp.forEach(eledata=>{
          // show only Status=='Active' events 
          //console.log(eledata.hubName);
          if(eledata.status =='Active'){
            this.hubs.push(eledata);
          }
        }); 
      }
    });
  }
  searchtargetofficers(){
    this.isSearchSubmitted = true;
    if (!this.searchtargetofficerform.valid) {
      return false;
    } else {
      console.log('hello');
    
      console.log(this.searchtargetofficerform.get("fofficerhub").value);
      console.log(this.searchtargetservicesform.get("fSertargettype").value);
      console.log(this.searchtargetservicesform.get("fSerservicehub").value);
      console.log(this.searchtargetservicesform.get("fSerperiod").value);

      this.overlays = [];
     
        this.getselectedofficers(this.searchtargetofficerform.get("fofficerhub").value);

   
         this.getselectedservices(this.searchtargetservicesform.get("fSertargettype").value, this.searchtargetservicesform.get("fSerservicehub").value,this.searchtargetservicesform.get("fSerperiod").value );
    
    
    }
  }


  searchtargetservices(){
    this.isSearchSubmitted = true;
    if (!this.searchtargetservicesform.valid) {
      return false;
    } else {
      console.log('hello');
      console.log(this.searchtargetofficerform.get("fofficerhub").value);
      console.log(this.searchtargetservicesform.get("fSertargettype").value);
      console.log(this.searchtargetservicesform.get("fSerservicehub").value);
      console.log(this.searchtargetservicesform.get("fSerperiod").value);

      this.overlays = [];
     
        this.getselectedofficers(this.searchtargetofficerform.get("fofficerhub").value);

   
        this.getselectedservices(this.searchtargetservicesform.get("fSertargettype").value, this.searchtargetservicesform.get("fSerservicehub").value,this.searchtargetservicesform.get("fSerperiod").value );
    
    }
  }


  COORDINATE_OFFSET = 0.00002; 
  getselectedofficers(user:any){   
    console.log('in 1'+user);

    this.userdetailsservice.getAllActiveUsers().subscribe(userdata =>{ 
      if(userdata!=null){
       // this.users = userdata;
      // console.log("length..."+userdata.length)
       if(user == null || user == undefined || user == '')
       {
        this.users  =   userdata.filter(e=>e.prefLocationOne!=null && e.userFirstName!='SURRENDERED' && e.userFirstName!='SPARE') 

       }else{

        this.users  =   userdata.filter(e=>e.prefLocationOne!=null &&  e.region === user && e.userFirstName!='SURRENDERED' && e.userFirstName!='SPARE') 
          console.log("users length "+this.users.length);


       }
        this.users.forEach(eledata=>{
         
            

              //get the latitude and longitude of preferred location one

               console.log("this.users.."+this.users.length)

                this.locationservice.getLocationsforId(eledata.prefLocationOne).subscribe(loc=>{
                  
                
                  let path='';

                 // console.log("eledata.userFirstName: "+eledata.userFirstName)
  
                  if(eledata.senior =='SFO'){
                    path ='../../../assets/layout/images/personpin_red.png'
                  } else {
                    path ='../../../assets/layout/images/personpin_purple.png'
                  }  
                  let randonnumber = Math.floor(Math.random() * 90 + 10)

                    //check if this location is duplicated
                   let checkduplicatelist = this.users.filter(d => d.prefLocationOne==eledata.prefLocationOne);
                  let lat =Number(loc.latitude)
                  let long = Number(loc.longitude)
                  if(checkduplicatelist.length>1)
                  {
                    lat = lat - randonnumber*this.COORDINATE_OFFSET;
                    long = long - randonnumber*this.COORDINATE_OFFSET;
                  }

                  this.overlays.push(new google.maps.Marker({ position: { lat: lat, lng: long }, 
                  title: eledata.userFirstName+" "+eledata.userLastName +"|"+eledata.userId + (checkduplicatelist.length>1?'| '+checkduplicatelist.length +' more users at same location ':''), icon: path,draggable:false }
                  
                  ));
                  
                })

               
              
        });                 
      }     
    });
  }

  clearselection()
  {
    console.log("clear selections")
    this.searchtargetofficerform= this.fb.group({
      ftargettype: ['', []],
      fservicehub: ['', []],
      fofficerhub: ['', []]
    });

    this.ngOnInit();

  }

  clearselectionservices()
  {
    console.log("clear selections services")
    this.searchtargetservicesform= this.fb.group({
      fSertargettype: ['', []],
      fSerservicehub: ['', []],
      fSerperiod: ['', []]
    });

    this.ngOnInit();

  }
  
  getselectedservices(targettype:any, fservicehub:any, fperiodId: any){    
   // alert("targettype"+targettype)
    //this.perferredservice.getAllPerferredService().subscribe(preferreddata =>{  

      this.visitservice.getVisitsPreferredServices().subscribe(preferreddata =>{     

      this.visit = preferreddata; 

      console.log("initially "+this.visit.length);
      this.visit = this.visit.filter(el=>(el.latitude!=null && el.longitude!=null))

      console.log("after latitude and longitude filtering"+this.visit.length);
      if(targettype!=undefined && targettype!=null && targettype!='')
      {
        this.visit = this.visit.filter(el=>(el.targetId==targettype));
        console.log("after targettype filtering"+this.visit.length);


      }
      console.log("fservicehub value"+fservicehub);

      if(fservicehub!=undefined && fservicehub!=null && fservicehub!='')
        {
          this.visit = this.visit.filter(el=>( el.managingRegion==fservicehub));
          console.log("after fservicehub filtering"+this.visit.length);

        }
        console.log("fperiodId value"+fperiodId);

        if(fperiodId!=undefined && fperiodId!=null && fperiodId!='')
          {
            this.visit = this.visit.filter(el=>(el.periodId==fperiodId));
            console.log("after periodId filtering"+this.visit.length);

    
          }
      console.log("this.visit"+this.visit.length)
      this.visit.forEach(eledata=>{    
      
         // console.log("eledata.servicename..."+eledata.serviceName)
             let path='';

             if(eledata.serviceType =='Centre-Based Care'){
              path ='../../../assets/layout/images/childcare_multicolor.png'
            } else if(eledata.serviceType =='Out of Scope')
            {
              path ='../../../assets/layout/images/mobile_bus1.png'

            }
            else {
             // path ='../../../assets/layout/images/MobileCare.png'

             path ='../../../assets/layout/images/fdc_color.png'
            }              
              this.overlays.push(new google.maps.Marker({ position: { lat: Number(eledata.latitude), lng: Number(eledata.longitude) }, 
              title: eledata.serviceName+"|"+eledata.preSerId, icon: path,draggable:false }
              
              ));
            
      });       
    }); 
  }
 /* saveassignment(){
    console.log(this.assignscheduleform.get("sflexperiod").value );
    console.log(this.selectedPrefService.eventKey);
    this.isSubmitted = true;    
    if (!this.assignscheduleform.valid) {
      return false;
    } else {      
      this.sse = new scheduleevent;
      this.sse.userId = this.selectedUser.userId;      
      this.sse.title = this.selectedPrefService.eventKey+"-"+this.selectedPrefService.serviceId;
      this.sse.eventType = this.selectedPrefService.eventKey;          
      this.sse.periodId = this.assignscheduleform.get("sflexperiod").value;
      this.sse.serviceId = this.selectedPrefService.serviceId;
      this.sse.futureVisit = 'Y';
      this.sse.createdBy = "System";
      this.sse.perSerId = this.selectedPrefService.perSerId;
      this.sse.createdDate = new Date();
      

      this.scheduleeventservice.create(this.sse).subscribe(res=>{
        this.messageService.add({ severity: 'success', summary: 'success', detail: 'Added schedule for '+this.selectedPrefService.serviceId });
        console.log("created !!!!!!!!!!!!!");    
        
        Once created Get EVENT_ID from SCHEDULE_SERVICE_EVENT table and update it back to colum EVENT_ID in SCHEDULE_PREFERRED_SERVICES.
            1) Get EVENT_ID from SCHEDULE_SERVICE_EVENT pass  PER_SER_ID
            2) get PER_SER_ID from SCHEDULE_PREFERRED_SERVICES pass
            3) Update the SCHEDULE_PREFERRED_SERVICES object from step 2 with the EVENT_ID retrived from Step 1

        
            this.scheduleeventservice.getSSEByPerSerId(this.selectedPrefService.perSerId).subscribe(sseData =>{      
              this.sse  = sseData; 
            
              
              this.perferredservice.getSchedulePerferredService(this.selectedPrefService.perSerId).subscribe(spsData =>{      
                this.sps  = spsData;   
                this.sps.eventId = this.sse.eventId;
                this.perferredservice.update(this.sps).subscribe(psData =>{      
                  this.overlays = [];
                  this.ngOnInit();
                  },err=>{

                    this.messageService.add({ severity: 'error', summary: 'success', detail: 'update schedule failed '+this.selectedPrefService.perSerId });
                    console.log("update failed !!!!!!!!!!!!!");
              
                }); 
              });
          });   
            this.ngOnInit();
      },err=>{
        this.messageService.add({ severity: 'error', summary: 'success', detail: 'Add schedule failed '+this.selectedPrefService.serviceId });
        console.log("failed !!!!!!!!!!!!!");
      });
    }
    this.isSubmitted = false; 
    this.selectedUser;
    this.assignscheduleform.reset();
    this.servicedialog = false; 
  
    

  }*/
}
