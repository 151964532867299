import { TOUCH_BUFFER_MS } from '@angular/cdk/a11y';
import { AotCompiler } from '@angular/compiler';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { hubs } from '../../domain/hubs';
import { locations } from '../../domain/locations';
import {User} from '../../domain/user';
import { Userdomain } from '../../domain/userdomain';
import { hubservice } from '../../service/hubservice';
import { locationservice } from '../../service/locationservice';
import { usermaintenanceservice } from '../../service/usermaintenanceservice';

@Component({
  selector: 'app-usermaintenance',
  templateUrl: './usermaintenance.component.html',
  styleUrls: ['./usermaintenance.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default

})
export class UsermaintenanceComponent implements OnInit {
  
  users: User[];
  status: any;
  statusreason: any;
  statusSel: any;
  location: any;
  allHubs: hubs;
  allLocations: locations;
  update_User_Modal: boolean;
  selected_User: User;
  update_User_Submitted: boolean = false;
  add_User_Submitted:boolean=false;
  User_Maintenance: any;
  selectedUser:User;
  AdduserMaintenance: boolean;
  titles: any;
  workingDaysWeek: { name: string; }[];
  mobility: { name: string; }[];
  userbyid: any;
  selectedStatus:any=[{name: 'Active', value: 'Active'}] ;

  intUserId:string='';
  newselecteduser: any = {};
  displayNotes: boolean;
  displayCOINotes: boolean;
  User_Main_tenance: Userdomain;
  isSubmitted: boolean;
  Preferred_Location: any;
  userstatus: any;
  role: string;
  add_UserSubmitted: boolean =false;

   constructor(private usermaintenanceservice: usermaintenanceservice,private ref:ChangeDetectorRef,
    private messageService: MessageService,private formBuilder: FormBuilder,
    private hubService:hubservice, private locationservice: locationservice) {

      /* these are for dropdown */
      this.titles = [
        {name: 'Senior Field Officer', value: 'SFO'},
        {name: 'Field Officer', value: 'FO'},
        {name: 'Field Support Officer', value: 'FSO'},
        {name: 'Senior Field Officer + View Only', value: 'SFORD'},
        {name: 'Field Officer + View Only', value: 'FORD'},
        {name: 'Field Support Officer + View Only', value: 'FSORD'},
        {name: 'HUB Manager', value: 'HUBM'},
        {name: 'HUB Coordinator', value: 'HUBC'},
        {name: 'HUB Manager + View Only', value: 'HUBMRD'},
        {name: 'HUB Coordinator + View Only', value: 'HUBCRD'},
        {name: 'View Only', value: 'VIEW'},
        {name: 'Admin', value: 'ADMIN'},
               
        
      ];
    this.status = [
      {name: 'Active', value: 'Active'},
      {name: 'Temporary Inactive', value: 'Temporarily Inactive'},
      {name: 'Permanent Inactive', value: 'Permanently Inactive'},
    ];

    this.statusreason = [
      {name: 'Maternity', value: 'Maternity'},
      {name: 'Internal secondment', value: 'Internal secondment'},
      {name: 'External secondment', value: 'External secondment'},
      {name: 'WHS', value: 'WHS'},
      {name: 'Manager Request', value: 'Manager Request'},
      {name: 'Project', value: 'Project'}
    ];

   

    this.workingDaysWeek = [
      {name: '1'},
      {name: '2'},
      {name: '3'},
      {name: '4'},
      {name: '5'},
    ];

    this.mobility = [
      {name: 'Yes'},
      {name: 'No'}
    ];
    
  }

   registerForm: FormGroup;
   backupUser=[];
   adduserMaintenanceform = this.formBuilder.group({
    userFirstName: ['',Validators.required],
    userLastName: ['',Validators.required],
    doeUser: ['',Validators.required],
    doeEmail: ['',Validators.required],
    title: ['SFO'],
    hub:[''],
    status:['Active'],
    parttimeflag:false,
    statusreason:[''],
    prefLocationOne:[''],
    prefLocationTwo:[''],
    wokingDaysWeek:['1'],
    mobileNo:[''],
    notes:[''],
    travelOutsideHUB:['No'],
    prefLocation:['No'],
  });
   

  ngOnInit(): void { 

      this.usermaintenanceservice.getAllUsers().subscribe(userdata =>{      
        this.users = userdata;
        if(userdata!=null){
          let userstemp =userdata;
          this.users = [];
          userstemp.forEach(eledata=>{
            eledata.userFullName = eledata.userFirstName + " " + eledata.userLastName;
            if(eledata.title== "SFO"){
              eledata.title = "Senior Field Officer";
            }else if(eledata.title== "FO"){
              eledata.title = "Field Officer";
            }else if(eledata.title== "FSO"){
              eledata.title = "Field Support Officer";
            }else  if(eledata.title== "SFORD"){
              eledata.title = "Senior Field Officer + View Only";
            }else if(eledata.title== "FORD"){
              eledata.title = "Field Officer + View Only";
            }else if(eledata.title== "FSORD"){
              eledata.title = "Field Support Officer + View Only";
            }else if(eledata.title== "HUBM"){
              eledata.title = "HUB Manager";
            }else if(eledata.title== "HUBC"){
              eledata.title = "HUB Coordinator";
            }else if(eledata.title== "HUBMRD"){
              eledata.title = "HUB Manager + View Only";
            }else if(eledata.title== "HUBCRD"){
              eledata.title = "HUB Coordinator + View Only";
            }else if(eledata.title== "VIEW"){
              eledata.title = "View Only";
            }else if(eledata.title== "ADMIN"){
              eledata.title = "Admin";
            }
            //for preSchlKindrPartOfSchl
            if(eledata.preSchlKindrPartOfSchl=="0"){
              eledata.preSchlKindrPartOfSchl="No";
            }else{
              eledata.preSchlKindrPartOfSchl="Yes";
            }
            // show only active users
            this.users.push(eledata);
            this.backupUser=[...this.users];
          }); 
        }
        this.getAllHubs();
        this.getAllLocations();
        this.statusChanged();
      });

    // register all forms groups in formbuilder {{(selectedNotes)?selectedNotes.mobileNo:''}}
    //edit
    this.registerForm = this.formBuilder.group({
      updateuserMaintenancegroup : this.formBuilder.group({
        ffirstname:[''],
        flastname:[''],
        fintUserId:[''],
        femail: [''],
        f_title:[''],
        fhub:[''],
        fstatus:[''],
        fparttimeflag:false,
        fstatusreason:[''],
        flocation_one:[''],
        f_location_two:[''],
        f_working_days:[''],
        fmobile: [''],
        ftravel:[''],
        fnote: [''],
        fcoi: [''],
      }),
      
    });
  }


  updateTitleChange(event){
    var title = event.value;

    if (title == "SFO" || title == "VIEW" || title == "FO" || title == "FSO") 
      {
       return this.role="ACO";
      }else if (title == "SFORD" || title == "FORD" || title == "FSORD") 
      {
       return this.role="SCHD_ACO_READONLY";
      }
      else if (title === "HUBM" || title === "HUBC")
      {
       return this.role="ROM";
      }  else if (title === "HUBMRD" || title === "HUBCRD")
      {
       return this.role="SCHD_ROM_READONLY";
      }
      else if (title === "ADMIN")
      {
       return this.role="SCHD_ADMIN";
      }
  }


 

  titleChange(event){
    var title = event;

    if (title == "SFO" || title == "VIEW" || title == "FO" || title == "FSO") 
      {
       return this.role="ACO";
      }else  if (title == "SFORD" || title == "FORD" || title == "FSORD") 
      {
       return this.role="SCHD_ACO_READONLY";
      }
      else if (title === "HUBM" || title === "HUBC")
      {
       return this.role="ROM";
      } else if (title === "HUBMRD" || title === "HUBCRD")
      {
       return this.role="SCHD_ROM_READONLY";
      }
      else if (title === "ADMIN")
      {
       return this.role="SCHD_ADMIN";
      }
  }

  changeTitle(){
    var title = this.selected_User.title;

    if (title == "Senior Field Officer") 
      {
       return "SFO";
      }
      else if (title === "Field Officer")
      {
       return "FO";
      }
      else if (title === "Field Support Officer")
      {
       return "FSO";
      }
      if (title == "Senior Field Officer + View Only") 
      {
       return "SFORD";
      }
      else if (title === "Field Officer + View Only")
      {
       return "FORD";
      }
      else if (title === "Field Support Officer + View Only")
      {
       return "FSORD";
      }
      else if (title === "HUB Manager")
      {
       return "HUBM";
      }else if (title === "HUB Coordinator")
      {
       return "HUBC";
      } else if (title === "HUB Manager + View Only")
      {
       return "HUBMRD";
      }else if (title === "HUB Coordinator + View Only")
      {
       return "HUBCRD";
      }else if (title === "View Only")
      {
       return "VIEW";
      }else if (title === "Admin")
      {
       return "ADMIN";
      }
  }
 
  AddUserMaintenance(){
    this.add_UserSubmitted = true;
    var role_d = this.titleChange(this.adduserMaintenanceform.get("title").value);
    if(this.adduserMaintenanceform.invalid){
      return;
    }else{
      this.isSubmitted = true;
      this.User_Main_tenance = new Userdomain;
      this.User_Main_tenance.userFirstName = this.adduserMaintenanceform.get("userFirstName").value; 
      this.User_Main_tenance.userLastName = this.adduserMaintenanceform.get("userLastName").value;
      this.User_Main_tenance.intUserId = this.adduserMaintenanceform.get("doeUser").value;
      this.User_Main_tenance.email = this.adduserMaintenanceform.get("doeEmail").value;
      this.User_Main_tenance.title = this.adduserMaintenanceform.get("title").value;
      this.User_Main_tenance.region = this.adduserMaintenanceform.get("hub").value;
      this.User_Main_tenance.status = this.adduserMaintenanceform.get("status").value;
      this.User_Main_tenance.parttimeflag = (this.adduserMaintenanceform.get("parttimeflag").value!=null && this.adduserMaintenanceform.get("parttimeflag").value==true)?"true":"false" ;

      this.User_Main_tenance.statusreason = this.adduserMaintenanceform.get("statusreason").value;

      this.User_Main_tenance.prefLocationOne = this.adduserMaintenanceform.get("prefLocationOne").value;
      this.User_Main_tenance.prefLocationTwo = this.adduserMaintenanceform.get("prefLocationTwo").value;
      this.User_Main_tenance.wokingDaysWeek = this.adduserMaintenanceform.get("wokingDaysWeek").value;
      this.User_Main_tenance.mobileNo = this.adduserMaintenanceform.get("mobileNo").value;
      this.User_Main_tenance.mobility = this.adduserMaintenanceform.get("travelOutsideHUB").value;
      this.User_Main_tenance.notes = this.adduserMaintenanceform.get("notes").value;
      this.User_Main_tenance.createdBy = localStorage.getItem("loginuserid"),
      this.User_Main_tenance.roleId = this.titleChange(this.adduserMaintenanceform.get("title").value);
      this.User_Main_tenance.preferredLocation = this.adduserMaintenanceform.get("prefLocationOne").value;
      console.log(this.User_Main_tenance);
      this.usermaintenanceservice.createUserMaintenance(this.User_Main_tenance).subscribe(res=>{
        this.messageService.add({ severity: 'success', summary: 'success', detail: 'New User Added.'});
        //console.log("-- new target created --");      
        this.ngOnInit();
      },err=>{
        this.messageService.add({ severity: 'error', summary: 'error', detail: 'New User addition failed.'});
        console.log("-- new target creatation failed--");      
      });
      this.adduserMaintenanceform.reset();
      this.AdduserMaintenance = false;
    }    
  }

  


  get validAddUser() { return this.adduserMaintenanceform.controls; }

  get validUser() { return (<FormGroup>this.registerForm.get('updateuserMaintenancegroup')).controls; }
  get uMobile() { return (<FormGroup>this.registerForm.get('updatemobilegroup')).controls; }
  get uEmail() { return (<FormGroup>this.registerForm.get('updateemailgroup')).controls; }
  get uTravel() { return (<FormGroup>this.registerForm.get('updatetravelgroup')).controls; }
  get uLocation() { return (<FormGroup>this.registerForm.get('updatePreferred_Location')).controls; }
  get uStatus() { return (<FormGroup>this.registerForm.get('updatestatusgroup')).controls; }


 
  
  
  exportExcel() {
   let exp_users = this.users.map(function(aUser){
      return {FirstName: aUser.userFirstName,
             LastName: aUser.userLastName,
             Role: aUser.title,
             Hub: aUser.region,
             Status: aUser.status,
             Reason: aUser.statusreason,
             Preferred_Location: aUser.preferredLocation,
             Travel_Outside_HUB: aUser.mobility,
             Email: aUser.email,
             Mobile_Phone: aUser.mobileNo,
             Notes: aUser.notes,
             Conflict_of_Interest: aUser.coinotes,
            };
    })
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(exp_users);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "users");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
      import("file-saver").then(FileSaver => {
          let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
          let EXCEL_EXTENSION = '.xlsx';
          const data: Blob = new Blob([buffer], {
              type: EXCEL_TYPE
          });
          FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      });
  }

  /* for edit */
  updateUser_Maintenance_model(users){ 
    this.update_User_Submitted = false;
    this.update_User_Modal = true;
    this.selected_User = users;
    this.userstatus=null;
    this.newSelectedUser();
  } 

  /* for default select value */
  newSelectedUser(){
    var title = this.changeTitle();
   

    this.updateuserMaintenancegroup.patchValue({
      ffirstname: this.selected_User.userFirstName,
      flastname: this.selected_User.userLastName,
      fintUserId: this.selected_User.intUserId,
      femail: this.selected_User.email,
      f_title: title,
      fhub: this.selected_User.region,
      fstatus: this.selected_User.status,
      fparttimeflag: (this.selected_User.parttimeflag=="true")?true:false,
      fstatusreason: this.selected_User.statusreason,
      flocation_one: this.selected_User.prefLocationOne,
      f_location_two: this.selected_User.prefLocationTwo,
      f_working_days: this.selected_User.wokingDaysWeek,
      fmobile: this.selected_User.mobileNo,
      ftravel: this.selected_User.mobility,
      fnote: this.selected_User.notes,
      fcoi: this.selected_User.coinotes,

    });
  }

  showusermaintenancemodel(){ 
    this.add_UserSubmitted=false;
    this.AdduserMaintenance = true;
  }

  updateUsermaintenance(){
    
    this.update_User_Submitted = true;
    if (!this.updateuserMaintenancegroup.valid){
      return false;
      }
      else{
        console.log("flag value"+this.updateuserMaintenancegroup.get("fparttimeflag").value)
        this.usermaintenanceservice.get(this.selected_User.userId).subscribe(userdata=>{
          this.User_Maintenance = userdata;
         
          this.User_Maintenance.userFirstName = this.updateuserMaintenancegroup.get("ffirstname").value;
          this.User_Maintenance.userLastName = this.updateuserMaintenancegroup.get("flastname").value;
          this.User_Maintenance.intUserId = this.updateuserMaintenancegroup.get("fintUserId").value;
          this.User_Maintenance.email = this.updateuserMaintenancegroup.get("femail").value;
          this.User_Maintenance.title = this.updateuserMaintenancegroup.get("f_title").value;
          this.User_Maintenance.region = this.updateuserMaintenancegroup.get("fhub").value;
          this.User_Maintenance.status = this.updateuserMaintenancegroup.get("fstatus").value;
          this.User_Maintenance.parttimeflag = (this.updateuserMaintenancegroup.get("fparttimeflag").value!=null && this.updateuserMaintenancegroup.get("fparttimeflag").value==true)?"true":"false";

          this.User_Maintenance.statusreason = this.updateuserMaintenancegroup.get("fstatusreason").value;
          this.User_Maintenance.prefLocationOne = this.updateuserMaintenancegroup.get("flocation_one").value;
          this.User_Maintenance.prefLocationTwo = this.updateuserMaintenancegroup.get("f_location_two").value;
          this.User_Maintenance.wokingDaysWeek = this.updateuserMaintenancegroup.get("f_working_days").value;
          this.User_Maintenance.mobileNo=this.updateuserMaintenancegroup.get("fmobile").value;
          this.User_Maintenance.mobility = this.updateuserMaintenancegroup.get("ftravel").value;
          this.User_Maintenance.notes = this.updateuserMaintenancegroup.get("fnote").value;
          this.User_Maintenance.coinotes = this.updateuserMaintenancegroup.get("fcoi").value;
         
          this.User_Maintenance.updatedBy = localStorage.getItem("loginuserid");
          if(this.role!=undefined && this.role!=null && this.role!='null')  // here is the bug
          {
            this.User_Maintenance.roleId = this.role;
          }
        
          
          this.usermaintenanceservice.update(this.User_Maintenance).subscribe(res=>{
            this.messageService.add({ severity: 'success', summary: 'success', detail: 'User details updated for '+this.selected_User.userId});
            console.log("--update success--");      
            this.ngOnInit();
          },err=>{
            this.messageService.add({ severity: 'error', summary: 'success', detail: 'User details update failed for '+this.selected_User.userId});
            console.log("-- updates failed --");
          });        
      });
    }
    
    this.update_User_Submitted = false;
    this.update_User_Modal = false;
    
  }

  get updateuserMaintenancegroup() {
    return this.registerForm.get('updateuserMaintenancegroup');
  }

  getAllHubs(){
    this.hubService.getHubs().subscribe(hubData=>{
      this.allHubs=hubData;
      console.log("-- all hubs--"+ this.allHubs);

    })
  }

  getAllLocations(){
    this.locationservice.getLocations().subscribe(locationData=>{
      this.allLocations=locationData;
      console.log(this.allLocations);
    })
  }

  showNoteDialog(users) {
    this.displayNotes = true;
    this.selected_User = users;
  }


  showCOIDialog(users) {
    this.displayCOINotes = true;
    this.selected_User = users;
  }

  async statusChanged()
  {
    let selectedUsers=[];
    this.users=[...this.backupUser];

    console.log(this.selectedStatus.length);

    if(this.selectedStatus.length===1)
    {
      console.log(this.selectedStatus[0].value)
      this.users = this.users.filter(e=>e.status===this.selectedStatus[0].value);
      console.log("this.users.length"+this.users.length)
    }
    else if(this.selectedStatus.length===2)
    {
      console.log(this.selectedStatus[0].value)
      console.log(this.selectedStatus[1].value)



      this.users = this.users.filter(e=>(e.status===this.selectedStatus[0].value  || e.status===this.selectedStatus[1].value));
      console.log("this.users.length"+this.users.length)

    }
    else if(this.selectedStatus.length===3)
    {
      console.log(this.selectedStatus[0].value)
      console.log(this.selectedStatus[1].value)

      console.log(this.selectedStatus[2].value)


      this.users = this.users.filter(e=>(e.status===this.selectedStatus[0].value  || e.status===this.selectedStatus[1].value ||e.status===this.selectedStatus[2].value));
      console.log("this.users.length"+this.users.length)

    }
    
      
  }
}
